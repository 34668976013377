<template>
    <div id="btn-return" class="mt-2">
        <v-btn text icon :to="{ name: 'tools' }">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
    </div>
</template>

<script>
  export default {
    name: "BtnReturn"
  }
</script>

<style scoped>

</style>